<template>
  <CToaster position="top-center">
    <CToast
      :color="alert.color"
      :show="alert.show"
      @update:show="hide"
      :key="alert.message"
      :autohide="alert.duration"
    >
      {{ alert.message }}
    </CToast>
  </CToaster>
</template>

<script>
export default {
  name: "TheToast",
  computed: {
    alert() {
      return this.$store.state.alert;
    },
  },
  methods: {
    hide() {
      this.$store.commit("hideAlert");
    },
  },
};
</script>
