<template>
  <TheToast/>
  <div class="c-app flex-row align-items-center" style="background: #F8F8F8;" >
    <CContainer class="w-[50%] flex-col-reverse">
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <router-view v-slot="{ Component, route }">
              <transition name="fade" mode="out-in">
                <component :key="route.path" :is="Component" />
              </transition>
            </router-view>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <LoginRight />
  </div>
</template>

<script>
import LoginRight from "@/components/LoginRight";
import TheToast from "@/containers/TheToast";

export default {
  name: "AccountContainer",
  components: { LoginRight, TheToast },
};
</script>
