<template>
  <CContainer class="login-right">
    <div class="image-container">
      <img
        src="/mazars-image.png"
        alt="mazars"
        class="full-width full-height"
      />
    </div>
    <div class="login-right__text center-content">
      <div class="text-container">
        <h2 class="welcome-heading">Welcome to the ChangeMaker Platform!</h2>
        <h4 class="mazars-solution">
          Make action & adoption the driving force behind your transformation
          project !
        </h4>
        <p class="addopt-platform" style="margin:0px; margin-top: 20px;padding:0">
          A Mazars solution to meet the challenges of the major transformations
          of the future The CHANGEMAKER platform is designed to facilitate the
          adoption of your transformation projects by your employees. It meets 3
          major challenges :
        </p>
        <p class="addopt-platform" style="margin:0px;margin-top:10px;padding:0">
          <ul>
            <li>Managing the adoption of your transformation project in an enthusiastic way, by getting employees involved.</li>
            <li>Gamification to increase employee mobilization around the project and boost adoption.</li>
            <li>Getting teams on board the project by making it easier for them to understand what they need to do and how they need to do it.</li>
          </ul>
        </p>
      </div>
    </div>
  </CContainer>
</template>

<style scoped lang="scss">
.login-right {
  background: #2a3486;
  color: #ffffff;
  padding: inherit;
}

.image-container {
  height: 50vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.login-right__text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh; /* Remaining half of the viewport height */
}

.text-container {
  max-width: 618px;
  text-align: left;
}

.welcome-heading {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.2; /* Adjust as needed */
  color: #ffffff;
}

.mazars-solution,
.addopt-platform {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
}
.mazars-solution {
  font-weight: 400;
}
.addopt-platform {

  font-weight: 200;
  font-size: 16px;
}

@media only screen and (max-width: 1250px) {
  .login-right {
    display: none;
  }
}
</style>
